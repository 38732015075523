@import '../mixins';

.sidebar-block {
  margin: $gutter $gutter $gutter 0;
  border: 1px solid $borderColor;

  .header {
    display: flex;
    border-bottom: 1px solid $borderColor;
    h3 {
      color: $textDark;
      white-space: nowrap;
      margin: $gutter * 0.75 $gutter;
    }
    button {
      margin: $gutter *.5;
      margin-left: auto;
    }
  }
  .content {
    padding: $gutter * 0.5;

    a, button {
      display: block;
      width: 100%;
      margin: $gutter * 0.5;
      color: $linkColor;
      font-family: inherit;
      font-weight: normal;
      text-align: left;
    }
  }
  @include breakpoint-small {
    margin: $gutter $gutter 0;
  }
}
