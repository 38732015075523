@import '../variables';
@import '../mixins';

.quiz-challenge {
    .complete {
        margin: $gutter;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .results {
        h1, h2 {
            color: $textWine;
            text-align: left;
        }
        h2 {
            font-size: $font-size-h2;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 $gutter;
            border-bottom: $border;
        }
        .content {
            padding: 8px $gutter $gutter;
            canvas {
                max-width: 100%;
                max-height: 300px;
            }

            table {
                @include table();
                font-size: 14px;

                .moduleTitle {
                    width: 227px;
                }

                .unitTitle {
                    width: 239px;
                }

                .score {
                    width: 197px;
                }

                .avg {
                    width: 92px;
                }

                .course {
                    width: 104px;
                }

                .national {
                    width: 117px;
                }

                .data {
                    text-align: right;
                }

                tbody > tr {
                    &:nth-child(even) {
                        background: none;
                    }
                }

                th {
                    height: 40px;
                    color: $darkBlue;
                    text-align: left;
                    padding-left: 5px;
                }
            }

            .footnote {
                font-size: 14px;
            }
        }
    }
}
