@import "../variables";
@import "../mixins";

.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;

  input[type=checkbox] {
    display: none;
      & + svg {
        margin-right: 10px;
      }
  }

  &.disabled {
    p {
      color: $grey5;
    }

    svg {
      color: $grey5 !important;
    }
  }

  .badge {
    color: $grey1;
    padding: 4px 8px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
    margin-left: 5px;
  }

  .migrated {
    background-color: $regularWine;
  }

  .exams-outstanding {
    background-color: $lighterBlue;
  }

  .not-supported {
    background-color: #4D4D4D;
  }

}
