@import '../mixins';
@import '../variables';

.confirm-icev-account {
  flex-direction: column;

  .account-confirmation {

    p {
      color: $darkBlue;
    }

    a.button {
      width: 40%;
      margin: 0 5px;
    }
  }
}
