@import '../variables';

.progressBar {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 12px;
  background: $grey2;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align: middle;

  .bar {
    height: 100%;
    background: $regularGreen;
    transition-property: width;
    transition-duration: 1s;
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    line-height: 24px;
    text-align: center;
    font-size: 13px;
    color: #394f6b;
  }
  @media print {
    background: transparent;
    box-shadow: none;
    border: 1px solid #ccc;
    .bar {
      box-shadow: inset 0 0 0 1000px #eee;
    }

    .label {
      font-size: 15px;
    }
  }
}
