@import '../variables';

.quiz-challenge {
  h2, h3 {
    color: $textDark;
    text-align: center;
  }

  h2 {
    font-size: $font-size-h1;
  }

  .question {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    padding: $gutter;
    ul {
      padding: 0;
      list-style: none outside none;

      li {
        margin: $gutter 0;
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: $gutter 0;
    }

    button {
      width: 100%;
    }
  }
}
