@import '../mixins';
@import '../variables';

.migrate-site-list {
  text-align: left;
  margin-bottom: $gutter;

  p {
    color: $textBlue;
  }

  .site-name {
    margin: 16px 0 0 0;
    font-size: 16px;
    font-weight: bold;
  }

  .checklist-course {
    display: flex;
  }

  .checkbox input[type=checkbox] + svg {
    color: $textBlue;
  }

  .checkbox > p {
    margin: 8px 0;
  }
}

.alert {
  margin-top: 16px;
}
