.migrate .logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 75px 0 35px 0;
  margin: 0 auto;

  .migration-logos {
    width: 450px;
  }
}

.sign-in-form {
  margin-top: 0;
}

