@import "../variables";
@import "../mixins";

.module-overview {
  background: $grey1;
  box-shadow: $boxShadow;

  .button.icon {
    margin: 0 $gutter * 0.5;
  }

  .container {
    padding: 6px $gutter;
    display: flex;
    align-items: center;
    img {
      height: 40px;
    }

    h1 {
      margin: 0;
      color: $textDarkGrey;
      font-size: 20px;

      &.space {
        margin-left: $gutter;
      }
    }
  }

  &.start {
    div {
      h1 {
        margin-left: $gutter;
      }
    }
  }
}
