@import '../variables';

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle-indeterminate {
  0% {
    stroke-dasharray: 1, 145;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 145;
    stroke-dashoffset: -54;
  }
  100% {
    stroke-dasharray: 89, 145;
    stroke-dashoffset: -143;
  }
}

svg.progress {
  width: 50px;
  height: 50px;


  circle {
    fill: none;
    stroke: $regularBlue;
    stroke-width: 3;
    stroke-linecap: round;
  }

  &.indeterminate {
    animation: rotate 2s linear infinite;
    circle {
      animation: circle-indeterminate 1.5s ease-in-out infinite;
    }
  }
}

