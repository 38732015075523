@import '../mixins';
@import '../variables';

.modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 101;

  .modal-body {
    position: absolute;
    top: 40%;
    min-width: 640px;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -40%);
    background: $white;
    border-radius: $radius;
    display: flex;
    justify-content: center;
    padding: 24px;

    @include breakpoint-small {
      min-width: calc(100% - 32px);
      max-width: calc(100% - 32px);
    }

    > article,
    > section {
      width: 100%;

      .close {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 1.5rem;
        height: 1.5rem;

        & svg {
          transform: translate(12px, -12px);
        }

        &:hover {
          background: none;
        }
      }

      h3 {
        margin-top: 0;
        color: $darkBlue;
        font-size: 18px;
        text-align: left;
      }

      p {
        text-align: left;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      button,
      .button {
        margin-left: $gutter * 0.5;
      }
    }
  }
}
