@import '../variables';
@import '../mixins';

section.landing{
  display: flex;

  @include breakpoint-small {
    align-items: flex-start;
    margin-top: 26px;
  }
  .choices{
    display: flex;
    width: fit-content;
    margin: 0 auto;
    box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    background: white;
    height: fit-content;

    @include breakpoint-small {
      box-shadow: none;
    }

    @include breakpoint-ultra-small {
      flex-direction: column;
      box-shadow: none;
    }

    .student,
    .teacher {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;

        svg {
          width: 150px;
          height: 150px;
          margin-bottom: 1em;
        }
    }

    .student{
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 210px;
        background: $grey3;
        left: auto;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint-ultra-small {
         width: 100%;
         height: 1px;
         top: auto;
         bottom: 0;
        }
      }
    }
  }
}
