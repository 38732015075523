@import '../mixins';

.vas-alert {

  .alert{
    margin: $gutter;
  }
}

.course {
  z-index: 1;

  .overview{
    display: flex;
    justify-content: space-between;
    margin: $gutter;
    align-items: center;

    .icon {
      padding: 8px;
    }
    h1 {
      margin: 0;
      text-align: left;
      font-size: 28px;
      color: $regularWine;
    }
  }

  section.site > .courses,
  section.course > .modules,
  section.module > .units
                          {


    > h3 {
      margin: 0;
      padding: $gutter * 0.5;
      background: $regularBlue;
      color: $white;
    }
  }

  section.module {
    > .overview {
      display: flex;
      align-items: center;

      > div {
        flex-grow: 1;
      }

      .grades {
        margin-bottom: $gutter;

        .in-progress {
          font-size: 3em;
          line-height: 1em;

          .label {
            @include sr-only;
          }
        }
      }
    }
    > .units > h3 {
      background: $regularWine;
    }
  }

  section.unit {
    > .overview {
      display: flex;
      margin: $gutter;
      justify-content: flex-end;
    }
  }

  @include breakpoint-mid {
    flex-wrap: wrap;
  }
  @include breakpoint-small {
    > .flex{
      flex-direction: column-reverse;
    }
  }
}
