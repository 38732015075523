@import '../variables';
div.container.exit-button {
  display: flex;
  justify-content: flex-end;
  padding: $gutter;
}
iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left:0;

  &.game {
    height: 540px;
    position: relative;
  }
}

