.patterns {
  padding-top: 100px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .default {
    font-size: 16px;
  }
}

