@import '../globalStyles';
@import '../mixins';


.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: fit-content;

  header,
  footer {
    padding: $gutter * 0.5;
  }

  .App-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    background-color: $regularBlue;

    img.App-logo {
      transform: translateY(1px);
    }

    .menuIcon {
      color: $white;
      margin-right: $gutter;

      &:hover {
        background: $lightBlue;
      }
      &:active {
        background: $darkBlue;
      }
    }

    .home-link{
      margin-right: auto;
    }

    .user-name {
      color: $white;
      margin: 0 $gutter;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  > .content {
    flex: 1 1 auto;
    position: relative;
    background: $grey1;

    &.site,
    &.sites,
    &.courses,
    &.settings {
      background: $white;
    }

    @include breakpoint-small {
      background: $white;
    }
  }

  .license-error {
    max-width: 402px;
    margin: 134px auto auto;
    padding: $gutter;

    @include breakpoint-small {
      box-shadow: none;
      margin: auto;
    }
  }
}

form {
  label {
    width: 100%;
    input {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      height: 40px;
      font-size: 16px;
      border: 1px solid $grey4;
      outline: none;

      &::placeholder {
        font-weight: 400;
      }
    }
    span {
      display: block;
      text-align: left;
    }
  }
}
