@import '../mixins';
@import '../variables';

.logo {
  display: block;
  margin: 0 auto 30px auto;
  padding-top: 75px;

  @include breakpoint-small {
    width: 127px;
    margin: 0 auto;
    padding-top: 50px;
  }

  + .sign-in-form {
    margin-top: 30px;

    @include breakpoint-small {
      margin-top: 0;
    }
  }
}

.sign-in-form {
  max-width: 370px;
  margin: 150px auto $gutter auto;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  padding: 24px;
  border-radius: 4px;
  background: $white;
  @include breakpoint-small {
    box-shadow: none;
    margin: $gutter auto;
  }

  h1, h2, h3, h4 {
    color: $textDark;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }

  h1 {
    margin-top: 0;
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-weight: bold;
    margin: 0 0 $gutter 0;
    font-size: 22px;
  }

  .hint {
    font-size: $font-size-small;
    text-align: center;
  }

  .signinLink {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20px;
    color: $lightBlue;
    font-weight: 500;
  }

  h4 {
    margin: 0 0 $gutter 0;
    font-size: 22px;
    color: $grey9;
    font-weight: bold;
  }

  p {
    text-align: left;
  }
  p.center {
    text-align: center;
    a {
      color: $lightBlue;
      font-weight: 500;
    }
  }

  .googleButton {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.15) !important;
    justify-content: center;

    > div {
      display: inline;
      padding: 0 10px !important;
      margin: 0 !important;
      svg {
        margin-left: 0;
        transform: translateY(-3px);
      }
    }



    span {
      font-size: $font-size-regular;
      color: $textDarkGrey;
    }

    & + .alert {
      margin-top: $gutter * 0.5;
    }
  }

  .breakLine {
    width: 100%;
    height: 1px;
    background: $grey3;
    margin-top: 24px;
  }

  button, #create-account {
    width: 100%;
    padding: 10px 0;
  }

  .separator {
    margin: 20px 0 $gutter 0;
    display: flex;
    align-items: center;
    text-align: center;
    color: $grey5;
    font-weight: 500;
    width: 100%;
    justify-content: center;

    &.gutter {
      margin: $gutter 0;
    }
  }

  &.recover-password,
  .age-check-message {
    h1 {
      color: $regularWine;
      font-size: 24px;
      font-weight: 700;
      margin-top: 0;
    }

    h2 {
      font-size: 20px;
      &:nth-child(3) {
        margin-bottom: 24px;
      }
    }

    .link {
      width: 100%;
      margin: 16px auto 0 auto;
      display: flex;
      justify-content: center;
      color: $linkColor;
      font-weight: 500;
    }

    .content-box {
      background: #F6F6F6;
      padding: 11.5px;
      margin: $gutter 0;
      color: $grey6;
      font-size: $font-size-small;

      h3 {
        margin-top: 0;
        margin-bottom: 1em;
        text-align: left;
        font-size: $font-size-small;
        font-weight: 500;
        color: $grey6;
      }

      p {
        text-align: left;
        margin-top: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        color: $linkColor;
      }
    }
  }

  .age-check {

    button {
      margin-bottom: $gutter;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.lti-course-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .lti-course-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    article {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      min-height: 100px;
      padding-bottom: 1rem;
      margin: 1rem;
      box-shadow: 0px 0px 20px 0px $grey4;

      h2 {
        text-decoration: none;
      }

      button {
        background: $regularWine;
        color: $white;
        border: none;
        width: 200px;
        height: 4rem;
        font-size: 18px;
        margin-bottom: 0.5rem;
      }
    }
  }
}



.site-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    max-width: 100%;
    min-height: 100px;
    padding: $listPadding;
    margin: $gutter;
    box-shadow: $listItemShadow;
    border: 1px solid $borderColor;

    h2, p {
      color: $textDark;
      margin: 0 0 $listPadding;
    }
  }

  .header-title {
    width: 100%;
    padding-left: 14px;
    margin: $gutter 0 0 0;
  }

  .site-list, .enrollment-form {
    display: flex;
    flex-wrap: wrap;

    .header {
     display: flex;
     justify-content: space-between;
     width: 100%;

     .name {
      color: $regularWine;
      text-align: left;
      width: 100%;
    }
    }
    .content {
      display: flex;
      width: 100%;

      div {
        &.logon {
          margin-left: 24px;
        }

        &.title{
          width: 180px;
        }

        p {
          font-size: 14px;
          color: $grey5;
        }

        h3 {
          font-size: 16px;
          color: $grey9;
          margin: 0;
        }
      }
    }

    article {
      flex-basis: calc(50% - 21px);
      margin: 14px 0 0 14px;
      min-width: 310px;
      padding: 24px;
      cursor: pointer;

      &:hover {
        box-shadow: $listItemShadowHover;
      }

      @include breakpoint-small {
        flex-basis: 100%;
        margin: $gutter $gutter 0;
      }

      @include breakpoint-large {
        flex-basis: calc(33.3% - 19px)
      }
    }

  }
}
