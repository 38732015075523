@import '../mixins';

.course.container  {
  counter-reset: question-number;
}

.course.container .unit .itemContainer.lessons .lessonsList .lessonTitle h3::before {
  counter-increment: question-number;
  content: counter(question-number) ". ";
  white-space: pre;
  color: $lighterBlue;
  font-weight: 400;
}
