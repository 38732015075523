.student-avatar {
  &.boy-2,
  &.boy-3 {
    #boy-1 {
      visibility: hidden;
    }
  }
  &.boy-2 #boy-2{
    visibility: visible !important;
  }
  &.boy-3 #boy-3{
    visibility: visible !important;
  }
  &.girl-2,
  &.girl-3 {
    #girl-1 {
      visibility: hidden;
    }
  }
  &.girl-2 #girl-2{
    visibility: visible !important;
  }
  &.girl-3 #girl-3{
    visibility: visible !important;
  }
}
