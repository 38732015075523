@import '../variables';

label {
  position: relative;

  .label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  input {
    &.password{
      padding-right: 40px;
      // Edge adds it's own 'reveal password' button, hide it so we don't have duplicates
      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }
    }

    &.error {
      border: 1px solid $textDanger;
      color: $textDark
    }

    &:disabled {
      background: $grey1;
      color: $textDark;
      opacity: 1;
    }
  }
  button.onlyIcon {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 4px;
    bottom: 4px;
  }
}
