@import '../variables';

.menuContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;
  visibility: hidden;

  &.show {
    width: 100%;
    visibility: visible;

    .menu {
      transform: translateX(0);
    }
  }

  .menu {
    background: $white;
    width: 342px;
    max-width: calc(50% - 48px);
    height: 100%;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.2);
    padding: 54px 24px 24px;
    position: fixed;
    transform: translateX(-400px);
    transition: transform ease-in-out 0.3s ;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .line {
      width: 100%;
      height: 1px;
      background: $grey4;
      position: absolute;
      left: 0;
    }

    .sign-out {
      width: 100%;
      color: $grey9;
      margin-bottom: 50px;

    }

    h1 {
      text-align: left;
      font-size: 18px;
      margin: 0 ;

    }

    ul {
      list-style: none;
      text-align: left;
      padding: 0;

      &.second {
        margin-top: $gutter * 2;
      }

      li {
        cursor: pointer;

        a {
          text-decoration: none;
        }

        &:hover {
          background: $grey1;
        }

        p {
          margin: 0;
          padding: $cellPadding;
          font-weight: 500;
          color: $grey9
        }
      }
    }

    > .close {
      width: initial;
      position: absolute;
      right: $gutter;
      top: $gutter;
    }
  }
}
