@import '../mixins';

.courses {
  .header {
    display: flex;
    justify-content: space-between;
    padding: $gutter;
    h1 {
      margin: 4px 0 0;
    }
  }

  .course {
    @include block-list-item();
    min-height: 120px;
    flex-direction: column;

    .course-images{
      position:absolute;
      left: 0;
      top: 0;
      width: 170px;
      height:100%;
      > .background {
        width: 120px;
        height: 100%;
      }
      > .avatar {
        position: absolute;
        left: 80px;
        top: 20px;
        height: 80px;
        width: 80px;
        border-radius: 40px;
        box-shadow: $listItemShadow;
        background: $grey2;
        color: $grey6;
        overflow: hidden;
        img {
          height: 100%;
          width: auto;
          margin: auto;
        }
        svg {
          position: absolute;
          left: -10%;
          width: 120% !important;
          height: 120% !important;
        }
      }
    }

    h2 {
      margin-left: 180px;
    }

    .details {
      margin-left: 180px;
      display: flex;
    }

    &:first-child,
    &:nth-child(5n+1) {
      .background {
        background: $background1;
      }
    }
    &:nth-child(5n+2) {
      .background {
        background: $background2;
      }
    }
    &:nth-child(5n+3) {
      .background {
        background: $background3;
      }
    }
    &:nth-child(5n+4) {
      .background {
        background: $background4;
      }
    }
    &:nth-child(5n+5) {
      .background {
        background: $background5;
      }
    }

    @include breakpoint-small {
      .course-images {
        width: 110px;

        > .background {
          width: 66px;
        }

        > .avatar {
          left: $listPadding;
        }
      }
      h2,
      .details {
        margin-left: 100px;
      }
    }
  }
}
