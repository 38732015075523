@import "../variables";
@import "../mixins";

.migration-account-creation{
  color: $darkBlue;

  .migrate-account {
    flex-direction: column;

    p {
      text-align: center;
    }
  }
}

.migration-account-error {
  margin: 0 auto;
  max-width: 580px;
  text-align: center;
  color: $darkBlue;

  img {
    width: 360px;
    margin-top: $gutter;
  }

  h1 {
    margin-top: 0;
  }

  p {
    font-size: 18px;
  }
}
