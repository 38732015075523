@import '../mixins';

.keyboard-results{

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $gutter;
    border-bottom: $border;
  }

  .content{
    padding: 8px $gutter $gutter;

    //h2 {
    //  font-size: 24px;
    //  line-height: 29px;
    //}

    table {
      @include table();
      margin-bottom: 36px;

      tbody > tr {
        &:nth-child(even) {
          background: none;
        }
      }

      tbody{

        tr {

          :nth-child(even){
            background: none;
          }

          .rank, .state {
            width: 72px;
          }

          .score {
            width: 178px;
          }

          .rank, .score, .myScore, .wpm {
            text-align: right;
          }

        }
      }

    }
  }


}
