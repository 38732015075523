@import '../variables';

.message-section {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 200px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $grey2;
}

.message-inner {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;

  svg { color: $textGrey; }

  h1{
    font-size: 24px;
    line-height: 29px;
  }
}
