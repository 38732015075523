@import "variables";

@mixin breakpoint-ultra-small {
  @media only screen and (max-width: 375px) {
    @content
  }
}

@mixin breakpoint-small {
  @media only screen and (max-width: 767px) {
    @content
  }
}

@mixin breakpoint-small-portrait {
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    @content
  }
}

@mixin breakpoint-mid {
  @media only screen and (min-width: 768px) {
    @content
  }
}

@mixin breakpoint-large {
  @media only screen and (min-width: 1200px) {
    @content
  }
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  outline: none;
  border: none;
  padding: $buttonPadding;
  border-radius: $radius;
  background: $buttonGrey;
  height: fit-content;
  font-size: $font-size-regular;
  line-height: 24px;
  text-align: center;
  color: $buttonText;

  svg {
    margin-left: 10px;
    vertical-align: bottom;
  }

  &.border {
    background: none;
    border: 1px solid $buttonGrey;
    padding: 7px 19px;
  }

  &.small,
  &.icon{
    padding: 4px;
  }

  &.icon {
    background: none;
    svg {
      margin: 0;
    }
    &:hover {
      background: rgba(0,0,0, 0.055);
    }
    &:active {
      background: rgba(0,0,0, 0.15);
    }

    &:disabled {
      background: none !important;
      svg {
        color: $textGrey;
      }
    }

    @media (hover: none) {
      background: $buttonGrey;
      &:active {
        background: $grey3;
      }
    }
  }

  &.onlyIcon {
    width: fit-content;
    padding: 4px;

    svg {
      width: 20px;
    }
  }

  &:hover {
    background: $buttonLightGrey;
    .tooltip {
      display: flex;
      opacity: 1;
    }
  }

  &:active {
    background: $grey3;
  }

  &:disabled {
    border: none;
    background: $buttonLightGrey;
    color: $buttonDarkGrey;
    cursor: not-allowed;
    .tooltip {
      color: $buttonDarkGrey;
      border-color: $buttonDarkGrey;
    }
  }

  &.link {
    background: none !important;
    padding: 0;
    text-decoration: underline;
    color: $linkColor;
    &:active {
      color: $linkActiveColor;
    }
    &:hover{
      color: $linkHoverColor;
    }
    svg {
      margin-right: 1em;
    }
  }


  &.primary,
  &.submit {
    background: $buttonYellow;
    &:hover {
      background: $buttonYellowHover;
    }
    &:active {
      background: $buttonYellowActive;
    }
    &:disabled {
      background: $buttonLightGrey;
    }
  }

  &.secondary {
    background: $buttonGrey;

    &:hover {
      background: $buttonGreyHover;
    }
  }

  &.correct {
    background: $buttonGreen;
  }

  &.danger {
    background: $buttonDanger;
    color: white;
  }

  .tooltip {
    display: none;
    opacity: 0;
    position: absolute;
    padding: 8px 12px;
    box-shadow: $tooltipShadow;
    background: $white;
    border: 1px solid $borderColor;
    top: 0;
    left: 50%;
    transform: translateY(-45px) translateX(-50%);
    transition: opacity ease-in-out 0.3s;
    justify-content: center;
    width: max-content;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;


    &:before {
      content: '';
      position: absolute;
      background: $white;
      width: 8px;
      height: 8px;
      transform: rotate(225deg);
      border-left: 1px solid $borderColor;
      border-top: 1px solid $borderColor;
      top: 31px;
      bottom: 0;
    }

    &.left {
      transform: translateY(-45px) translateX(-15px);
      &:before {
        left: 10px;
      }
    }

    &.right {
      transform: translateY(-45px) translateX(-100%);
      left: 30px;
      &:before {
        left: auto;
        right: 13px;
      }
    }
  }
}

@mixin tip {
  font-size: 11px;
  color: $white;
  background: $lightBlue;
  margin: 0 0 0 $gutter * 0.5;
  height: fit-content;
  border-radius: $tipRadius;
  padding: 4px 6px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;

  &.in-progress {
    background: $regularWine;
  }

  &.completed {
    background: $lightGreen;
  }
}

@mixin block-list-item {
  position: relative;
  padding: $listPadding;
  border: 1px solid $borderColor;
  margin: $gutter;
  box-shadow: $listItemShadow;
  display: flex;

  &:hover{
    border-color: $borderColorDark;
    box-shadow: $listItemShadowHover;
  }

  > a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    color: transparent;
    z-index: 1;
  }


  h2 {
    text-align: left;
    margin: 4px 0 $listPadding;
    display: flex;
    align-items: center;

    .tip {
      margin-left: 8px;
    }
  }

  .details {
    display: flex;

    > div {
      margin-right: 24px;

      .label {
        font-size: $font-size-small;
        color: $textGrey;
      }

      p {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }

  &.closed,
  &.blocked {
    cursor: not-allowed;
    h2 {
      color: $grey6;
    }
  }
}

@mixin table {
  width: 100%;
  border-collapse: collapse;

  tr > td {
    padding: $cellPadding;
  }

  thead > tr > td {
    font-size: 14px;
    color: $darkBlue;
    font-weight: 500;
  }

  tbody > tr {
    &:nth-child(even) {
      background: $grey1;
    }
    & > td {
      border: $border;
    }
  }

}

@mixin locked {
  background: #46a486;
}