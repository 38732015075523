@import "../variables";

.profile {
  width: 320px;
  h1 {
    margin: 0 0 16px 0;
  }

  form {
    .hint {
      font-size: 14px;
      color: $grey6
    }
    
    .actions {
      display: flex;
      justify-content: space-between;

      button {
        width: 100px;
      }

      .primary {
        width: 210px;
      }
    }
  }
  
  button {
    width: 100%;
    justify-content: center;
  }
}