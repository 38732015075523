@import url('//fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('//fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import './mixins';
@import './variables';

html,
body {
  height: 100%;
  overflow: auto;
  @media print {
    height: auto;
  }
}

body {
  margin: 0;
  padding: 0;
  background: $white;
  color: $textDark;
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-regular;
  font-weight: 400;
  line-height: 1.2;
}

article,
div,
header,
section {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
  color: $textWine;
  font-weight: 500;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: $font-size-h1;
}

h2, h3, h4 {
  font-size: $font-size-h2;
}

strong {
  font-weight: 500;
}

button,
a.button {
  @include button
}

a.button {
  color: inherit;
  text-decoration: none;
}

.tip {
  @include tip
}

label {
  display: block;
  margin-bottom: $gutter;
}

#root{
  height: 100%;
}

.error {
  color:$textDanger;
}

.subtle {
  color: $textGrey;
  font-size: $font-size-small;
}

.container {
  width: 100%;
  max-width: $containerMaxWidth;
  min-width: $containerMinWidth;
  margin: 0 auto;
}

.sr-only {
  @include sr-only();
}

.hide {
  display: none;
}

.show {
  display: block;
}

.flex {
  display: flex;
}

// a11y overrides
a, button, input {
  &:not(iframe) {
    &:focus{
      outline: 1px auto black;
    }
  }
}

.locked {
  @include locked;
}
