@import "../variables";

svg {
  &.block {
    transform: rotateX(180deg);
  }
  &.completed {
    color: $regularGreen;
  }
  &.in-progress {
    color: $regularOrange;
  }
}
span.icon {
  &.not-started:before{
    content: '--';
  }
}

.toggleContainer {
  height: 23px;
  .closeArea {
    display: none;
    position: absolute;
    width: 100%;
    left:0;
    top: 0;
    height: 100%;
    z-index: 10;

    &.active {
      display: block
    }

  }

  .toggle {

    background: none;
    padding: 0;

    svg {
      margin: 0;
    }

    &.active {
      svg {
        color: $grey9 !important;
      }
      .tooltip {
        display: flex;
        opacity: 1;
      }
    }

}

}
