@import '../mixins';
@import '../variables';

.vas-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: $darkBlue;
  margin: $gutter;
  width: 100%;

  .message-text {
    width: 100%;
    padding: 0 $gutter;
  }

  .vas-action-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: $gutter;
  }

  .screenshot{
    border: 1px solid #979797;
    margin: 0 $gutter;
    img{
      max-width: 100%;
    }
  }

  .button {
    margin: $gutter;
  }

  .retake-button {
    font-weight: 500;
    margin-left: $gutter $gutter $gutter 0;
  }
}
