@import '../variables';

.tooltip-container {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 5;

  .tooltip {
    position: absolute;
    left:0; top: -32px;
    height: 20px;
    padding: 8px 12px;
    z-index: 10;
    font-weight: 500;
    box-shadow: 0 4px 6px -2px rgba(33, 37, 41, 0.1);
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    color: $darkerBlue;

    &::before {
      content: '';
      position: absolute;
      bottom: -15%; left: 15%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 0 15px;
      border-color: white transparent transparent transparent;
    }
  }
}
