@import "../variables";
@import '../mixins';

.orientation-warning {
  display: none !important;

  @include breakpoint-small-portrait {
    display: block !important;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .description {
      width: 100%;
    }

    .primary {
      width: 100%;
      margin-top: 12px;
    }

    .rotation {
      width: 72px !important;
      height: 72px !important;
      margin: 20px;
    }
  }
}
