@import '../mixins';
@import '../variables';

.course-migration {
  flex-direction: column;

  .course-migration-checklist {

    h1 {
      margin-top: 0;
    }

    .alert {
      text-align: left;
    }

    #selectAll ~ p {
      color: $textBlue;
    }

    .button-section {
      display: flex;
      justify-content: flex-start;

      button {
        margin-right: $gutter;
      }
    }
  }

}
