@import '../variables';
@import '../mixins';

.moduleItem {
  position: relative;
}

.modules > .module {
  @include block-list-item();

  .picture {
    position: relative;
    height:94px;
    width: 72px;
    overflow: hidden;
    img {
      display:block;
      height: 100%;
      width: auto;
    }
  }

  .content {
    margin-left: 24px;
  }

  &.due-passed {
    .due-date {
      color: $textWine
    }
  }

  &.closed,
  &.blocked {
    .picture {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $grey2;
      svg {
        color: $grey5;
      }
    }
  }
}
