.lessons {
  .reset,
  .blank{
    width: 32px
  }
  .icons-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
