@import "../variables";
@import "../mixins";

.alert {
  display: flex;
  align-items: flex-start;
  padding: 11px;
  margin-bottom: $gutter;
  font-size: 15px;
  color: $textDanger;
  background: $backgroundDanger;

  &.info {
    color: $textBlue;
    background: $backgroundInfo;
  }

  &.success {
    color: $textGreen;
    background: $backgroundSuccess;
  }

  &.warning {
    color: $textWarning;
    background: $backgroundWarning;
  }

  &.banner {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    .countdown {
      background: white;
      padding: 4px;
      min-width: 20px;
    }
  }

  a {
    color: inherit;
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: inline;
    margin: -4px $listPadding;
  }
  .icon.close {
    color: inherit;
    margin-left: auto;
  }

  .alert-actions {
    margin-left: auto;
    white-space: nowrap;
    a, button {
      margin-left: $listPadding;
    }
    a {
      color: $linkColor;
    }
  }
  @include breakpoint-small {
    display: block;
    i {
      float: left;
    }

    p {
      padding-left: 48px;
    }

    .alert-actions {
      padding-top: $listPadding;
      text-align: right;
    }
  }
}

form .alert{
    background: none !important;
    padding: 3px 0 0;
    align-items: flex-start;
    margin-top: -10px;

    svg {
      min-width: 24px;
      background: none;
      margin: -3px 10px 0 0;
      padding: 0;
    }
}
