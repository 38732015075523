@import '../mixins';
@import '../variables';


.itemContainer {
  width: 100%;
  padding: $gutter;

  &.tests {
    padding-top: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    &.quiz {
      margin-top: $gutter;
    }

    thead > tr {
      border: none;

      &:hover {
        background: none;
      }

      th {
        h3 {
          color: $darkBlue;
        }

        &:nth-child(1) {
          h3 {
            margin-left: 40px;
          }
        }
      }

      &.moduleHeader {
        th {
          h3 {
            margin-left: 0;
          }
        }
      }
    }

    tr {
      border-top: $border;
      border-bottom: $border;

      &.closed,
      &.blocked {
        color: $textGrey;
        cursor: not-allowed;
      }

      &:hover {
        background: $lighterYellow;
      }

      p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
      }

      th, td {
        text-align: left;
        padding: 0 10px;
        width: 120px;
        font-size: 14px;

        &:nth-child(1) {
          width: auto;
        }

        &.reset {
          button {
            margin: auto 0 auto auto;
          }
        }

        &.unitTitle, &.lessonTitle, &.quizTitle {
          display: flex;
          align-items: center;

          .icon {
            min-width: 40px;
            display: flex;
            align-items: center;

            svg {
              width: 20px !important;

            }
          }
        }

        h3, .title {
          font-size: 14px;
          padding: 12px 0;
          color: $lightBlue;

          span + span {
            margin-left: 8px;
          }

          a {
            color: $lighterBlue;
            font-weight: 400;
          }
        }

        svg {
          color: $grey9;
        }
      }

      &.closed {
        //cursor: not-allowed;
        h3, p, svg, .title {
          color: $textGrey
        }
      }

      &.due-passed {
        .due-date {
          p {
            color: $textWine
          }
        }
      }


      &.blocked {

        h3, p, svg {
          color: $grey4
        }


      }

    }
  }

  &.customModule {
    table {
      tr {
        td {
          width: 33%;

          a {
            color: $linkColor;
          }

          h3 {
            color: $grey9;
            font-weight: 400;
          }
        }

        &.header {
          th:nth-child(1) {
            h3 {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

}


