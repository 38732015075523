/* Colors */
$roseWine: #ca6e97;
$regularWine: #9A1E56;
$lightestBlue: #438AC5;
$lighterBlue: #1A6BAC;
$lightBlue: #146DB6;
$regularBlue: #105792;
$darkBlue: #0C416D;
$darkerBlue: #082C49;
$lighterYellow: #fbf4dc;
$lightYellow: #F1D474;
$regularYellow: #EDC951;
$darkYellow: #BEA141;
$regularOrange: #D27E18;
$lightGreen: #44A449;
$regularGreen: #1C7C32;
$lightBlueGreen: #58CDA8;
$black: #000000;
$grey1: #F2F2F2;
$grey2: #E6E6E6;
$grey3: #D9D9D9;
$grey4: #C0C0C0;
$grey5: #9A9A9A;
$grey6: #737373;
$grey7: #4D4D4D;
$grey8: #3E3E3E;
$grey9: #262626;
$greyTransparent: rgba(33,37,41,0.1);
$greyDarkTransparent: rgba(33,37,41,0.25);
$white: #FFFFFF;

/** Text Colors */
$textBlue: $darkBlue;
$textGreen: $regularGreen;
$textDark: $grey9;
$textWine: $regularWine;
$textGrey: $grey5;
$textDarkGrey: $grey8;
$textDanger: #C50000;
$textWarning: #5F5020;

/** Button Colors */
$buttonLightGrey: $grey1;
$buttonGrey: $grey2;
$buttonGreyActive: $grey3;
$buttonGreyHover: $buttonLightGrey;
$buttonDarkGrey: $grey4;
$buttonText: $darkerBlue;
$buttonDark: $grey7;
$buttonYellow: $regularYellow;
$buttonYellowActive: $darkYellow;
$buttonYellowHover: $lightYellow;
$buttonGreen: $regularGreen;
$buttonDanger: $textDanger;

//** Link Colors */
$linkColor: $lightBlue;
$linkHoverColor: $lightestBlue;
$linkActiveColor: $regularBlue;
$linkDisabledColor: $grey4;

/** BackgroundColors */
$background1: $lightBlueGreen;
$background2: $regularOrange;
$background3: $lightestBlue;
$background4: $roseWine;
$background5: $lighterYellow;
$backgroundDanger: #FBE6E6;
$backgroundWarning: #FBF4DC;
$backgroundInfo: #D0E2F0;
$backgroundSuccess: #d9edda;

/** paddings */
$cellPadding: 12px 10px 10px 10px;
$listPadding: 13px;
$inputPadding: 11px;
$buttonPadding: 8px 20px;
$iconButtonPadding: 11.5px 12px;

/** Border */
$borderColor: $grey3;
$borderColorDark: $grey4;

/** Radius */
$radius: 5px;
$tipRadius: 3px;

/* shadows */
$tooltipShadow: 0 4px 6px -2px $greyTransparent;
$listItemShadow: 2px 2px 4px 0 $greyTransparent;
$listItemShadowHover: 2px 2px 4px $greyDarkTransparent;
$boxShadow: 0 1px 0 0 $grey3;

$font-size-h1: 28px;
$font-size-h2: 20px;
$font-size-regular: 16px;
$font-size-small: 14px;

/**
Style Variables
 */
$border: 1px solid $borderColor;
$gutter: 16px;
$containerMaxWidth: 1200px;
$containerMinWidth: 640px;
