@import "../mixins";

.lrn {
  color: $textDarkGrey !important;
  font-size: 16px !important;
  font-family: 'LearnosityMath', 'Roboto', sans-serif !important;

  .lrn-assess-spinner {
    width: 50px;
    height: 50px;
    background-image: url('../images/progress.svg');
  }
}

.lrn.lrn-assess {
  .items {
    .progress span {
      background: $lightBlue;
    }
    .inline-item {
      border-top: $border;
      margin: $gutter 0;
      padding-top: $gutter;
      .lrn_widget{
        margin: 0;
        padding: 0;
      }
    }
  }
  .lrn-assess-switch {
    .lrn-assess-switch-trigger {
      font-weight: 500;
      padding-left: $gutter * 2;

      &:before {
        background: none;
        border: 2px solid $textDarkGrey;
      }
    }
    input.lrn-assess-input[aria-checked="true"] + .lrn-assess-switch-trigger:after {
      background: $lightBlue;
      border-color: $lightBlue;
    }
  }

  .lrn_widget {
    .lrn_valid,
    .lrn_selected {
      background: $grey1 !important;
      > label.lrn-label:before {
        content: '' !important;
        width: 24px;
        height: 24px;
      }

      &.lrn_correct {
        background: $backgroundSuccess !important;
        border-left-color: $backgroundSuccess !important;

        > label.lrn-label:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.17 7-8.09 8.09-3.67-3.68-1.41 1.41 5.09 5.09 9.49-9.5z' fill='%231C7C32'/%3E%3C/svg%3E");
        }
      }

      &.lrn_incorrect {
        background: $backgroundDanger !important;
        border-left-color: $backgroundDanger !important;
        > label.lrn-label:before{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m15.59 17-3.59-3.59-3.59 3.59-1.41-1.41 3.59-3.59-3.59-3.59 1.41-1.41 3.59 3.59 3.59-3.59 1.41 1.41-3.59 3.59 3.59 3.59z' fill='%23C50000'/%3E%3C/svg%3E");
        }
      }
    }
    .lrn_valid {
      border-left-color: $grey1 !important;
      > label.lrn-label:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18.17 7-8.09 8.09-3.67-3.68-1.41 1.41 5.09 5.09 9.49-9.5z' fill='%23C0C0C0'/%3E%3C/svg%3E");
      }
    }

    &.lrn_mcq .lrn_mcqgroup.lrn_mcqgroup-vertical .lrn-mcq-option,
    &.lrn_mcq .lrn_mcqgroup.lrn_mcqgroup-horizontal .lrn-mcq-option {
      input{
        &.lrn-input {
          top: 12px;
          left: 12px;
          bottom: auto;
          clip-path: circle(0);
        }
        &:focus{
          outline: transparent;
          border: none;
        }
      }

      label  {
        padding-left: 40px;
        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: 10px;
          display: block;
          width: 16px;
          height: 16px;
          padding: 0;
          border: 2px solid $grey4;
          background: white;
          z-index: 1;
        }
      }

      input[type="radio"] + label:before {
        border-radius: 50%;
      }

      &.lrn_selected {
        label {
          &:before {
            border: 2px solid $lightBlue;
          }
          &:after {
            z-index: 2;
          }
        }
        input[type="radio"] + label:after {
          content: '';
          position: absolute;
          top: 15px;
          left: 15px;
          display: block;
          border: 5px solid $lightBlue;
          border-radius: 50%;
        }

        input[type="checkbox"] + label:before {
          background: #fff url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogPGc+DQogIDxwYXRoIHRyYW5zZm9ybT0icm90YXRlKDIuNzUzNTc4MTg2MDM1MTU2MiAzMS4yMDU1NjI1OTE1NTI2NSwyOS44MzE1MzkxNTQwNTI3KSAiIGlkPSJzdmdfMTEiIGQ9Im00NS40MjQ4LDEwLjc3ODMybDYuMDcwMzEsNC40MDkxOGwtMjMuNDkzMTYsMzMuNjk3MjdsLTE3LjA4NTk0LC0xMS44NjUyM2wzLjc5Njg4LC02LjE2OTkzbDExLjE1MzMyLDcuODMxMDZsMTkuNTU4NTksLTI3LjkwMjM1eiIgc3Ryb2tlLWxpbmVjYXA9Im51bGwiIHN0cm9rZS1saW5lam9pbj0ibnVsbCIgc3Ryb2tlLWRhc2hhcnJheT0ibnVsbCIgc3Ryb2tlLXdpZHRoPSJudWxsIiBmaWxsPSIjMDAwMDAwIi8+DQogPC9nPg0KPC9zdmc+") center center no-repeat;
          background-size: 100% 100%;
        }

        &.lrn_correct,
        &.lrn_incorrect {
          label {
            &:before {
              border-color: $grey4;
            }

            &:after {
              border-color: $grey4;
            }
          }
        }
      }
    }
  }
  .lrn_btn,
  .lrn_btn_blue {
    @include button;
    padding: 7px 8px !important;
    color: $buttonText !important;
    border: none !important;
    border-radius: $radius !important;
    height: 38px;
    font-size: $font-size-regular !important;
    &:after{
      display: none !important;
    }
    &.test-review-screen {
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(0 -1)' %3E%3Cpath d='m0 0h24v24h-24z'/%3E%3Cpath d='m4 8h4v-4h-4zm6 12h4v-4h-4zm1-1h2v-2h-2zm-7 1h4v-4h-4zm0-6h4v-4h-4zm1-1h2v-2h-2zm5 1h4v-4h-4zm6-10v4h4v-4zm-6 4h4v-4h-4zm1-1h2v-2h-2zm5 7h4v-4h-4z' fill='%23082C49' /%3E%3C/g%3E%3C/svg%3E");
        float: right;
      }
    }

    &:disabled {
      background-color: $buttonLightGrey !important;
      color: $buttonDarkGrey !important;
    }

    .btn-label {
      line-height: 24px;
      color: inherit !important;
    }
  }

  .lrn-bottom-region,
  .lrn-bottom-right-region {
    .test-review-screen {
      &:before {
        margin-left: 12px;
      }
    }
    .lrn_btn.item-prev,
    .item-next {
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        margin: 0 !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%23082C49' d='M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z' /%3E%3C/svg%3E");
      }
      &:disabled:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%23C0C0C0' d='M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z' /%3E%3C/svg%3E");
      }
    }

    .item-next {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .lrn-region:not(.lrn-items-region),
  .dialogs {
    .lrn_btn_blue {
      color: $buttonText !important;
      border: none !important;
      background: $buttonYellow !important;

      &:before {
        color: $buttonText !important;
      }

      &:hover {
        background: $buttonYellowHover !important;
      }

      &:active {
        background: $buttonYellowActive !important;
      }

      &:hover {
        background: $buttonYellowHover !important;
      }

      &.item-next {
        background: $buttonGrey !important;

        &:hover {
          background: $buttonGreyHover !important;
        }

        &:active {
          background: $buttonGreyActive !important;
        }
      }
    }
  }

  .dialogs,
  .review-screen {
    .lrn_btn,
    .lrn_btn_blue {
      padding: 7px 20px !important;
    }

    .lrn-dialogs-close-btn,
    .lrn-assess-close-review-screen-btn {
      top: 8px !important;
      right: 8px !important;
      width: 32px !important;
      height: 32px !important;
      padding: 4px !important;
      box-sizing: border-box;
      border: none;
      border-radius: $radius;
      background: none !important;

      &:hover,
      &:focus {
        background: $buttonLightGrey !important;
      }
      &:before {
        content: '';
        float: none;
        width: 24px !important;
        height: 24px !important;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23082C49' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E");
        transform: none;
      }
      &:after {
        display: none;
      }
    }
  }

  .review-screen {
    .filter-disabled {
      display: none !important;
    }

    .popover-content::first-line {
      font-weight: 500;
      text-transform: capitalize;
    }
    .item-card {
      &:before {

        display: none !important;
      }
      &.active {
        &:before {
          display: block !important;
          background-color: $lightBlue !important;
        }
        &:after {
          border-color: transparent transparent transparent $lightBlue !important;
        }
      }
    }

    .lrn-assess-review-fully_attempted {
      .overlay{
        background: $backgroundInfo !important;
      }
    }
  }

  .dialogs {
    .lrn-dialog-default {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      background: rgba(0, 0, 0, 0.8);

      .modal-dialog {
        position: absolute;
        top: 40%;
        min-width: 640px;
        left: 50%;
        width: fit-content;
        transform: translate(-50%, -40%);

        .modal-header {
          padding: $gutter $gutter 0;

          h2 {
            font-size: $font-size-h2;
            font-weight: 500;
          }
        }

        .modal-body {
          padding: $gutter;
          border: none;
          font-size: $font-size-regular;
          .lrn-assess-spinner {
            position: relative;
            margin-top: $gutter * 3;
            margin-bottom: $gutter * 3;
          }
        }

        .modal-footer {
          display: flex;
          flex-direction: row-reverse;
          padding: $gutter;
          border: none;

          button {
            margin-left: $gutter * 0.5 ;
          }
        }
      }

      &.review-screen {
        position: absolute;
        top: 0 !important;
      }
    }
  }


  .question-tts {
    margin: $gutter 0;
    audio {
      height: 1.5em;
    }

    button {

      font-weight: 500;

      svg {
        display: inline-block;
        height: 1.5em;
        width: 1.5em;
        margin-left: 0;
      }
    }
  }

  @media print {
    position: relative;
    .lrn-region-group {
      display: block;
    }
    .inline-item {
      position:relative;
      display: block;
      .content {
        position: relative;
        display: block;
        break-inside: avoid;
      }
    }
  }
}
