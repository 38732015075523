$face0: #EAD2BB;
$face1: #CAA66C;
$face2: #AA8461;
$hair0: #653A19;
$hair1: #402009;
$hair2: #000000;
$shirt0: #A5A5A5;
$shirt1: #8F4E4E;
$shirt2: #6DAA3A;


.teacher-avatar {
  &.style-2,
  &.style-3,
  &.style-4,
  &.style-5{
    #hair-1 {
      visibility: hidden;
    }
  }
  &.style-2 #hair-2 {
    visibility: visible !important;
  }
  &.style-3 #hair-3 {
    visibility: visible !important;
  }
  &.style-4 #hair-4 {
    visibility: visible !important;
  }
  &.style-5 #hair-5 {
    visibility: visible !important;
  }
  &.hair-0 .hair{
      fill: $hair0 !important;
  }
  &.hair-1 .hair{
      fill: $hair1 !important;
  }
  &.hair-2 .hair {
      fill: $hair2 !important;
  }
  &.face-0 #face path {
      fill: $face0 !important;
  }
  &.face-1 #face path {
      fill: $face1 !important;
  }
  &.face-2 #face path {
      fill: $face2 !important;
  }
  &.shirt-0 #shirt path {
      fill: $shirt0 !important;
  }
  &.shirt-1 #shirt path {
      fill: $shirt1 !important;
  }
  &.shirt-2 #shirt path {
      fill: $shirt2 !important;
  }
}
