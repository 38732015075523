@import '../mixins';
@import '../variables';

.enroll-modal {
  .modal-body {
    min-width: 370px;

    section {
      width: 100%;
      h3 {
        margin: 1.2em 0 $gutter;
        text-align: center;
        font-size: 22px;
        color: $grey9;
      }

      form {
        label {
          .placeholder {
            display: none;
          }
        }
      }

      .success-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        .alert {
          width: 100%;
          margin-bottom: $gutter;
        }
      }

      .actions {
        justify-content: space-between;
        margin: 0;

        button {
          width: 48%;
          margin: 0;
          justify-content: center;
        }
      }
    }

    @include breakpoint-small {
        min-width: auto;
    }
  }
}
