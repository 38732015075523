@import '../mixins';

.assessment-results {
  padding: 16px;

  .results-header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 $gutter*1.5;

    button {
      margin-left: $gutter * 0.5;
      margin-right: 0;
    }

    h3 {
      margin: 0 $gutter;
      color: $grey8;

      &.space {
        margin-right: 24px;
      }
    }

    .alert {
      margin: 0 auto 0 0;
    }
  }

  > h2,
  > h3 {
    width: 100%;
    margin: $gutter * 0.5;

    &.grade {
      color: $grey8
    }
  }

  button {
    @include button;
    margin: auto;
  }

  section.answers {
    margin:24px;


    h3 {
      margin: 0;
      color: $white;
      background: $regularWine;
    }

    article {
      text-align: left;
      margin-top: 31px;

      .bold {
        font-weight: 500;
        font-size: 18px;
      }

      > div {

        p {
          margin: 0;
          font-size: 16px;
        }

        .correct {
          color: $lightGreen !important;
          font-weight: 500;
        }

        .error {
          font-weight: 500;

          .answer {
            margin: 0 0 10px 0;
          }
        }

        .result {
          display: flex;
          align-items: center;
          transform: translateX(-4px);
          margin: 22px 0 10px 0;

          p {
            margin: 0 0 0 4px;
            font-size: 18px;
          }
        }
      }
    }
  }

  table.attempts {
    @include table();
    margin-bottom: $gutter * 2;
    font-size: 14px;
  }
}
