@import '../variables';

.migrate.progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: blue;

  .migrate-progress {
    text-align: left;

    h1 {
      margin-bottom: 18px !important;
    }

    .steps {
      margin: 20px 0 20px 0;
      padding: 0;
      list-style: none outside none;

      li {
        position: relative;
        padding: 8px 36px 8px 28px;
        font-size: 16px;
        color: #1A6BAC;

        &.processing {
          color: #777777;
        }

        svg {
          position: absolute;
          left: 0;
          top: 4px;
          color: $textGreen;
        }
      }
    }

    .student-info-warning {
      color: $textBlue;
      font-weight: bold;
      margin-top: 18px;
    }

    .error-warning {
      color: $textWarning;
      background: $backgroundWarning;

      .error-report {
        display: flex;
        min-width: 500px;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        margin-top: 16px;
        padding: 11px;

        .warning-text {
          margin-right: auto;
          padding-left: 10px;
          text-align: left;
        }

      }

      .error-site {
        background: #F4DF97;
        padding: 5px 13px 4px 13px;
      }

      .error-course {
        padding: 11px 0 4px 13px;

        .error-row {
          display: flex;
          align-items: flex-start;

          svg {
            color: $textDanger;
            margin-right: 7px;
            min-width: 24px;
          }

          .error-name {
            padding: 4px 0;
          }

          .error-wording {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .cs-email {
        padding: 22px 0 26px 0;
        display: flex;

        .customer-support-link {
          padding-left: 5px;
        }
      }

      .error-site,
      .error-course,
      .cs-email {
        margin: 0 44px 0 25px;
      }

      .course-name,
      .error-name,
      .error-site,
      .cs-email {
        font-weight: 500;
      }


    }

    .show-report {
      background: none;
      padding: 0;
      color: $linkColor;
      text-decoration: underline;
    }

    a.button {
      background: #EDC951;
      margin-left: 0;
    }
  }
}
