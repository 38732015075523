@import '../variables';

.landing-message {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $listPadding;
  font-size: 18px;
  text-align: center;

  img {
    max-height: 40vh;
    margin: 20px auto;

    &.logo {
      padding: 0;
    }
  }
}