@import '../mixins';
@import '../variables';

.content.migrate{
  background: $grey1 !important;
}

.course-migration,
.migration-account-creation,
.migration-unavailable,
.confirm-icev-account,
.confirm-email-received {
  display: flex;
  justify-content: center;
  align-items: center;
}

.migrate-account,
.migrate-progress,
.account-confirmation,
.course-migration-checklist,
.confirm-email {
  height: fit-content;
  min-width: 636px;
  max-width: 640px;
  margin: 60px auto $gutter auto;
  padding: 24px;
  background: $white;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  text-align: center;
}

.migration-unavailable {
  flex-direction: column;

  .migration-unavailable-message {
    min-width: 636px;
    max-width: 640px;
    margin: 60px auto $gutter auto;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
    padding: 24px;

    a.button {
      background: #EDC951;
      margin-left: 0;
    }
  }

  h1 {
    margin-top: 0;
  }

  p {
    color: $textBlue;
  }
}

.confirm-email {
  text-align: left;

  h1 {
    margin-top: 0;
  }

  p {
    color: $textBlue;
  }

  img {
    border: 2px solid #02649A;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.20);
    max-width: 590px;
  }

  a.button {
    margin-top: $gutter;
    margin-left: 0;
    background: $buttonYellow !important;
  }
}

